
import React, { useState, useEffect, useRef } from "react"
import Layout from "./components/layout"
import logo from './images/logo192.png'
import { Row, Col, Card, CardBody, CardFooter, Input, FormGroup, Label } from "reactstrap"
import { Helmet } from 'react-helmet'
import logo151 from './images/logotype.png'
import logo152 from './images/pictorial-mark.png'
import logo153 from './images/abstract-mark.png'
import logo154 from './images/emblem-or-enclosure.png'
import logo161 from './images/web2.png'
import logo162 from './images/contemporary.png'
import logo163 from './images/classic.png'
import logo164 from './images/detailed.png'
import logo165 from './images/complex-illustration.png'
import logo171 from './images/serif.png'
import logo172 from './images/slab-serif.png'
import logo173 from './images/sans-serif.png'
import logo174 from './images/script.png'
import logo175 from './images/blackletter.png'

const pertanyaanAwal = 'Are you Married/DeFacto'

type mainProps = {
  uid: string,
  firebaseApp: firebase.app.App
}

const App = (props: mainProps) => {
  const defdataQ: any = {}
  const [dataQ, setDataQ] = useState(defdataQ)
  const [displayState, setDisplayState] = useState("pertanyaan0")
  const [complateQuestion, setComplateQuestion] = useState(0)
  const defValdata: any = "YES" 
  const [valData, setValData] = useState(defValdata)
  const [joinRadio, setJoinRadio] = useState({})
  const [slidePercent, setSlidePercent] = useState(0)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const txt1: any = useRef(null);

  let display = <div />
  console.log("valData", valData)
  useEffect(() => {
    const x = valData ? valData : pertanyaan[displayState] && pertanyaan[displayState].input && pertanyaan[displayState].input.devValue ? pertanyaan[displayState].input.devValue : 0
    const y = pertanyaan[displayState] && pertanyaan[displayState].input && pertanyaan[displayState].input.range && pertanyaan[displayState].input.range[1] ? pertanyaan[displayState].input.range[1] : 1000000000
    const pr = x / y * 100
    setSlidePercent(pr)
  }, [displayState, valData])
  useEffect(() => {
    txt1.current.focus();
  }, [])

  const pertanyaanExtra: any[] = []
  let cat = <span />
  if (displayState === 'done') {
    display = <h3>
      Thank you for completing our Branding Questionnaire!
    </h3>
  } else {

    cat = <Row style={{ textAlign: 'center' }}>
      <Col xs='12'>
        <h4 style={{ marginTop: 0 }}> {pertanyaan[displayState].category ? pertanyaan[displayState].category : ''} </h4>
        <p> {pertanyaan[displayState].subCategory ? pertanyaan[displayState].subCategory : ''} </p>
      </Col>

    </Row>

    if (pertanyaan[displayState].centang && pertanyaan[displayState].input.type === 'checkbox') {
      for (const x of pertanyaan[displayState].centang) {
        pertanyaanExtra.push(
          <div className="form-check">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" onChange={(evt) => {
                if (evt.target.checked) {
                  const data = dataQ
                  if (!data[pertanyaan[displayState].codeData]) {
                    data[pertanyaan[displayState].codeData] = []
                  }
                  data[pertanyaan[displayState].codeData].push(x)
                  console.log(data)
                  setDataQ(data)

                } else {
                  const data = dataQ
                  const y = data[pertanyaan[displayState].codeData].indexOf(x)
                  data[pertanyaan[displayState].codeData].splice(y, 1)
                  console.log(data)
                  setDataQ(data)
                }
              }} />
              <span className="form-check-sign"></span>
              {x}
            </label>
          </div>
        )
      }
    }

    if (pertanyaan[displayState].centang && pertanyaan[displayState].input.type === 'radio') {
      for (const x of pertanyaan[displayState].centang) {
        pertanyaanExtra.push(
          <FormGroup key={x} check className="form-check-radio">
            <Label check>
              <Input
                defaultValue="option1"
                name="exampleRadios"
                type="radio"
                onClick={() => {
                  setValData(x)
                }}
              ></Input>
              <span className="form-check-sign"></span>
              {x}
            </Label>
          </FormGroup>
        )
      }
    }
    if (pertanyaan[displayState].centang && pertanyaan[displayState].input.type === 'join-radio') {
      for (const y of pertanyaan[displayState].centang) {
        let tmp: any[] = []
        for (let x of y) { 
          tmp.push( 
            <FormGroup key={x} check className="form-check-radio " inline style={{minWidth: 200}}>
              <Label check>
                <Input
                  defaultValue="option1"
                  name={y}
                  type="radio"
                  onClick={() => {
                    const xx: any = joinRadio
                    xx[y] = x
                    console.log("joinRadio", JSON.stringify(xx) )
                    setJoinRadio(xx)

                  }}
                ></Input>
                <span className="form-check-sign"></span>
                {x}
              </Label>
            </FormGroup> 
          )
        }
        pertanyaanExtra.push(
          <div key={y}>
            <hr style={{margin: 0, marginTop: 10}}/>
            {tmp}
          </div>
        )

      }
      pertanyaanExtra.push(
        
          <hr style={{margin: 0, marginTop: 10}}/> 
      )
    }

    display = <div>
      <Row className="justify-content-center">
        <Col xs='12'>
          <h3> {slidePercent > 20 && dataQ[pertanyaanAwal] === 'YES' && pertanyaan[displayState].pertanyaan.length > 1 ? pertanyaan[displayState].pertanyaan[1] : pertanyaan[displayState].pertanyaan[0]} </h3>
          <p style={{ display: pertanyaan[displayState].sub ? '' : 'none' }}>{pertanyaan[displayState].sub} </p>
        </Col>
      </Row>

      <Row className="justify-content-center" style={{ marginBottom: 50, display: pertanyaan[displayState].input.type === 'text' ? '' : 'none' }}>
        <Col md={pertanyaan[displayState].input.width ? pertanyaan[displayState].input.width : 10}>
          <input className="form-control" style={{ fontSize: 18, textAlign: 'center' }} type='text' placeholder={pertanyaan[displayState].input.placeholder ? pertanyaan[displayState].input.placeholder : ''}
            value={valData ? valData : ''}
            onChange={(evt: any) => {
              const val = evt.target.value
              setValData(val)
            }}
            onBlur={(evt: any) => {
              const val = evt.target.value
              setValData(val)
            }}
          />
        </Col>
      </Row>
      <Row style={{ display: pertanyaan[displayState].input.type === 'textarea' ? '' : 'none' }}>
        <Col xs={12} style={{ marginTop: 0 }}>
          <Input
            rows="3"
            ref={txt1}
            type="textarea"
            value={valData ? valData : ''}
            placeholder={valData}
            onChange={(evt: any) => {
              setValData(evt.target.value)
            }}

          ></Input>
        </Col>
      </Row>
      <Row style={{ display: pertanyaan[displayState].input.type === 'file' ? '' : 'none' }}>
        <Col xs={12} style={{ marginTop: 0 }}>
          <Input
            rows="3"
            ref={txt1}
            type="file"

            onChange={async (evt: any) => {
              const files = evt.target.files[0]
              const data = dataQ

              const file = await props.firebaseApp.storage().ref(`client-branding/${props.uid}/${files.name}`).put(files)
              const url = await file.ref.getDownloadURL()

              data[pertanyaan[displayState].codeData] = url
              setValData(url)

            }}

          ></Input>
        </Col>
      </Row>
      <Row style={{ display: pertanyaan[displayState].input.type === 'checkbox' || pertanyaan[displayState].input.type === 'radio' ? '' : 'none' }}>
        <Col xs={12} style={{ marginTop: 0, textAlign: 'left' }}>
          {pertanyaanExtra}
        </Col>
      </Row>
      <Row style={{ display: pertanyaan[displayState].input.type === 'join-radio' || pertanyaan[displayState].input.type === 'radio' ? '' : 'none' }}>
        <Col xs={12} style={{ marginTop: 0, textAlign: 'left' }}>
          {pertanyaanExtra}
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ marginTop: 0 }}>
          <p style={{ display: pertanyaan[displayState].sub2 ? '' : 'none' }}>{pertanyaan[displayState].sub2} </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ marginTop: 0 }}>
          <p style={{ display: errorMessage ? '' : 'none', color: '#ff0000', fontWeight: 800 }}>{errorMessage} </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={6} md='4'>
          <button style={{ display: pertanyaan[displayState].btn1 && pertanyaan[displayState].btn1.type ? '' : 'none' }} className={`btn btn-${pertanyaan[displayState].btn1 ? pertanyaan[displayState].btn1.type : ''} btn-lg`} onClick={() => {
            const data = dataQ
            data[pertanyaan[displayState].codeData] = valData
            setValData(undefined)
            setDataQ(data)
            setDisplayState(pertanyaan[displayState].btn1.newState)
            setErrorMessage(undefined)
            setComplateQuestion(pertanyaan[displayState].btn1.percentageState)
          }}>{pertanyaan[displayState].btn1 ? pertanyaan[displayState].btn1.text : ''} </button>
        </Col>
        <Col xs={6} md='4'>
          <button className={`btn btn-${pertanyaan[displayState].btn2.type} btn-lg`} onClick={() => {
            const data = dataQ
            let err = 1
            let selesai = 0

            if (displayState === 'pertanyaan18') {
              if (valData !== undefined) {
                data[pertanyaan[displayState].codeData] = valData
                setErrorMessage(undefined)
                props.firebaseApp.database().ref(`branding/${props.uid}`).set(data).then(() => {
                  selesai = 1
                  setDisplayState('done')
                  setComplateQuestion(100)
                })
              } else {
                setErrorMessage(pertanyaan[displayState].errorMessage)
              }
            } else {
              if (displayState === "pertanyaan6") {
                if(pertanyaan[displayState].centang.length === Object.keys(joinRadio).length) {
                  data[pertanyaan[displayState].codeData] = joinRadio
                  err = 0
                }

              } else {
                if ((valData !== undefined || displayState === 'pertanyaan0') || (pertanyaan[displayState].errorMessage === '')) {
                  err = 0
                }
              }
              
            }

            if (selesai === 0) {
              if (err === 0) {
                if (displayState !== 'pertanyaan6') {
                  data[pertanyaan[displayState].codeData] = valData
                }
                if (displayState === 'pertanyaan0') {
                  data[pertanyaan[displayState].codeData] = 'YES'
                }
                console.log("displayState", displayState)
                setDataQ(data)
                setValData(undefined)
                setDisplayState(pertanyaan[displayState].btn2.newState)
                setErrorMessage(undefined)
                setComplateQuestion(pertanyaan[displayState].btn2.percentageState)

              } else {
                setErrorMessage(pertanyaan[displayState].errorMessage)
              }
            }

          }}>{pertanyaan[displayState].btn2.text} </button>
        </Col>
      </Row>
      <Row style={{ display: pertanyaan[displayState].content ? '' : 'none' }}>
        <Col xs={12} style={{ marginTop: 0 }}>
          {pertanyaan[displayState].content}
        </Col>
      </Row>
    </div>
  }

  console.log("dataQ", dataQ)

  return (
    <Layout header={<Header />}>
      <Helmet>
        <style type="text/css">
          {
            ` 

            .noUi-base {
              background-image: linear-gradient(to right, #ff0000, #ff0000 ${slidePercent}%, #eee ${slidePercent}%,#eee 100%) !important; 
            }
            
            `
          }
        </style>
      </Helmet>
      <Row style={{ marginTop: 50, textAlign: 'center' }}>
        <Col xs='12'>
          <h3> LOGO & BRANDING QUESTIONNAIRE</h3>
        </Col>

      </Row>
      {cat}
      <Row>
        <Col>
          <Card>
            <CardBody style={{ textAlign: "center" }}>
              {display}
            </CardBody>
            <CardFooter>
              <div style={{ width: '100%', background: '#eee' }} >
                <div style={{ width: '100%', backgroundImage: `linear-gradient(      to right,       #007bff,      #007bff ${complateQuestion}%,      #aaa ${complateQuestion}%,          #aaa 100%    )` }}>
                  <p style={{ textAlign: "center", lineHeight: '30px', margin: 0, color: '#fff', fontWeight: 500 }}>You're {complateQuestion}% Complete</p>
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>

    </Layout>
  )
}

export default App;

const Header = () => {

  return (
    <header
      style={{
        background: `#2ca8ff85`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          textAlign: 'center'
        }}
      >
        <img src={logo} alt="" style={{ margin: '10px  auto', height: 75 }} />
      </div>
    </header>
  )

}



const pertanyaan: any = {
  pertanyaan0: {
    category: '',
    subCategory: '',
    pertanyaan: ['Let’s get started!'],
    codeData: 'started',
    sub: `We like to ask our clients about their brand and how they see it, in their own eyes. Our designers then have a story and a vision, and can bring it to life for you. The following questionnaire walks you through this vision, with literal questions about images and colours, as well as more abstract areas of emotions and descriptions. 
    If you already have ideas for what you would like, feel free to share those too. There is space at the end for additional thoughts or to upload materials and images. 
    `,
    errorMessage: 'Please answer the question',

    btn2: {
      text: 'Start ',
      newState: 'pertanyaan1',
      percentageState: 5,
      type: 'info'
    },
    input: {
      type: 'none'
    }
  },
  pertanyaan1: {
    category: 'Brand Values',
    subCategory: '',
    pertanyaan: ['Company mission statement'],
    codeData: '01 - Company mission statement',
    sub: "We’ll start nice and easy.  Tell us a few sentences about your company - What do you do? Who do you do it for?  And why do you do it?",
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan0',
      percentageState: 0,
      type: 'info',
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan2',
      percentageState: 10,
      type: 'info'
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }

  },
  pertanyaan2: {
    category: 'Brand Values',
    subCategory: '',
    pertanyaan: ['Target audience and customers'],
    codeData: '02 - Target audience',
    sub: "Tell us a bit more about your clients.  Who are they?  Where are they?  What do they like and like to do? If your clients are more business-minded, what do their companies do?",
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan1',
      percentageState: 5,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan3',
      percentageState: 15,
      type: 'info'
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    },
  },
  pertanyaan3: {

    category: 'Brand Values',
    subCategory: 'Please answer the question',
    pertanyaan: ['Benefit of your product/service'],
    codeData: '03 - Benefit of your product',
    sub: "Why would someone want or need your product?  Does it save your customers money? Does it help them increase ROI?",
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan2',
      percentageState: 10,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan4',
      percentageState: 20,
      type: 'info'
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    },
  },
  pertanyaan4: {
    category: 'Brand Values',
    subCategory: ' ',
    pertanyaan: ['Five words that represent your brand'],
    codeData: '04 - Five words that represent your brand',
    sub: 'Think about your brand’s personality. How would you describe it?',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan3',
      percentageState: 15,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan5',
      percentageState: 25,
      type: 'info'
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    },
  },
  pertanyaan5: {
    category: 'Brand Values',
    subCategory: '',
    pertanyaan: ['Three messages your brand needs to communicate'],
    codeData: '05 - Three messages your brand needs to communicate',
    sub: 'What do you want your clients to know about your brand and company.  Try to be brief - your messages must be clear, and clearly communicated',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan4',
      percentageState: 20,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan6',
      percentageState: 30,
      type: 'info'
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    },
  },
  pertanyaan6: {
    category: 'Brand Attributes',
    subCategory: 'The following questions will help to describe your brand, as you see it. ',
    pertanyaan: ['How does your brand’s image fall between these opposing characteristics?'],
    codeData: '06 - brand image',
    centang: [
      ['Masculine', 'Feminine'],
      ['Simple', 'Intricate'],
      ['Black & White', 'Colorful'],
      ['Conservative', 'Extravagant'],
      ['Approachable', 'Authoritative'],
      ['Necessity', 'Luxury'],
      ['Fun', 'Serious'],
      ['Professional', 'Casual'],
      ['Modern', 'Classic'],
      ['Sporty', 'Elegant'],
      ['Extreme', 'Safe']
    ],
    errorMessage: `Oops, it seems you haven't answered one or more of the multiple choice questions.`,
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan5',
      percentageState: 25,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan7',
      percentageState: 35,
      type: 'info'
    },
    input: {
      type: 'join-radio'
    }
  },
  pertanyaan7: {
    category: 'Brand Attributes',
    subCategory: 'The following questions will help to describe your brand, as you see it. ',
    pertanyaan: [`What colors represent your brand?`],
    codeData: '07 - What colors',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan6',
      percentageState: 30,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan8',
      percentageState: 40,
      type: 'info'
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan8: {
    category: 'Brand Attributes',
    subCategory: 'The following questions will help to describe your brand, as you see it. ',
    pertanyaan: [`What colors would you not like to see? `],
    codeData: '08 - What colors would you not like to see',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan7',
      percentageState: 35,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan9',
      percentageState: 45,
      type: 'info'
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan9: {
    category: 'Brand Attributes',
    subCategory: 'The following questions will help to describe your brand, as you see it. ',
    pertanyaan: ['What words would you use to describe your brand’s image?'],
    codeData: '09 - What words would you use to describe your brand  image',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan8',
      percentageState: 40,
      type: 'info',
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      newState: 'pertanyaan10',
      percentageState: 50,
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan10: {
    category: 'Brand Attributes',
    subCategory: 'The following questions will help to describe your brand, as you see it. ',
    pertanyaan: ['What words would you not want used to describe your brand’s image?'],
    codeData: '10 - What words would you not want used to describe your brands image',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan9',
      percentageState: 45,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      newState: 'pertanyaan11',
      percentageState: 55,
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan11: {
    category: 'Brand Attributes',
    subCategory: 'The following questions will help to describe your brand, as you see it. ',
    pertanyaan: ['What attributes and/or emotions do you want associated with your brand?'],
    codeData: '11 - What attributes and or emotions do you want associated with your brand',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan10',
      percentageState: 50,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      percentageState: 60,
      newState: 'pertanyaan12',

    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan12: {
    category: 'Brand Attributes',
    subCategory: 'The following questions will help to describe your brand, as you see it. ',
    pertanyaan: ['What attributes and/or emotions would you not want associated with your brand?'],
    codeData: '12 - What attributes and or emotions would you not want associated with your brand',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan11',
      percentageState: 55,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      percentageState: 65,
      newState: 'pertanyaan13',
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan13: {
    category: 'Design',
    subCategory: 'Now we’re getting into the look and feel of your brand just a step further, as we think about your logo. ',
    pertanyaan: ['For your current logo,what do you like about it?'],
    codeData: '13 - For your current logo what do you like about it',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan12',
      percentageState: 60,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      newState: 'pertanyaan14',
      percentageState: 70,
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan14: {
    category: 'Design',
    subCategory: 'Now we’re getting into the look and feel of your brand just a step further, as we think about your logo. ',
    pertanyaan: ['What do you dislike?  Or, why do you feel that it’s time for an upgrade?'],
    codeData: '14 - What do you dislik',
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan13',
      percentageState: 65,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      newState: 'pertanyaan15',
      percentageState: 75,
    },
    input: {
      type: 'textarea',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan15: {
    category: 'Design',
    subCategory: 'The following questions will help you narrow down your visual preferences.',
    pertanyaan: ['Which one of these logo formats do you believe best represents your brand?'],
    codeData: '15 - Which one of these logo formats do you believe best represents your brand',
    centang: ['Logotype', 'Pictorial Mark', 'Abstract Mark', 'Emblem/Enclosure'],
    content: <>
      <hr></hr>
      <h3 style={{ fontWeight: 800, marginTop: 50 }}>Logo Format Example</h3>
      <div style={{ textAlign: 'left' }}>
        <h4>Logotype</h4>
        <p>This format includes your company’s name in stylized typeface and/or your company initials in a simple shape.</p>
        <img src={logo151} alt="" />
        <h4>Pictorial Mark</h4>
        <p>This mark includes literal or representative imagery to symbolize your brand.</p>
        <img src={logo152} alt="" />
        <h4>Abstract Mark</h4>
        <p>This format includes abstract shapes and symbols. These types of logos are very unique and identifiable often without the company name.</p>
        <img src={logo153} alt="" />
        <h4>Emblem or Enclosure</h4>
        <p>This features the name or initials of the company, usually as part of a pictorial element or shape.</p>
        <img src={logo154} alt="" />
      </div>
    </>,
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan14',
      percentageState: 70,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      newState: 'pertanyaan16',
      percentageState: 80,
    },
    input: {
      type: 'radio',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan16: {
    category: 'Design',
    subCategory: 'The following questions will help you narrow down your visual preferences.',
    pertanyaan: ['Which one of these logo styles do you feel best represents your brand?'],
    codeData: '16 - Which one of these logo styles do you feel best represents your brand',
    centang: ['Web 2.0', 'Contemporary', 'Classic', 'Detailed/Stylized', 'Complexed Illustration'],
    content: <>
      <hr></hr>
      <h3 style={{ fontWeight: 800, marginTop: 50 }}>Logo Style Example</h3>
      <div style={{ textAlign: 'left' }}>
        <h4>Web 2.0</h4>
        <p>Common elements typically include vibrant colors, a subtle 3D feel, bold type, color transitions, shadows, gloss and reflection.</p>
        <img src={logo161} alt="" />
        <h4>Contemporary</h4>
        <p>Common elements include fresh colors, stylized imagery and clean type.</p>
        <img src={logo162} alt="" />
        <h4>Clasic</h4>
        <p>Common elements include timeless colors, literal imagery and traditional type.</p>
        <img src={logo163} alt="" />
        <h4>Detailed/Stylized</h4>
        <p>Common elements include detail, textures, flowing lines and distinctive type.</p>
        <img src={logo164} alt="" />
        <h4>Complex illustration</h4>
        <p>These include a hand-drawn feel depicting a scene or character.</p>
        <img src={logo165} alt="" />
      </div>
    </>,
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan15',
      percentageState: 75,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      newState: 'pertanyaan17',
      percentageState: 85,
    },
    input: {
      type: 'radio',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan17: {
    category: 'Design',
    subCategory: 'The following questions will help you narrow down your visual preferences.',
    pertanyaan: ['What style of font do you feel best represents your brand?'],
    codeData: '17 - What style of font do you feel best represents your brand',
    centang: ['Serif', 'Slab serif', 'Sans Serif', 'Script', 'Blackletter'],
    content: <>
      <hr></hr>
      <h3 style={{ fontWeight: 800, marginTop: 50 }}>Font Style Example</h3>
      <div style={{ textAlign: 'left' }}>
        <h4>Serif</h4>
        <img src={logo171} alt="" />
        <h4>Slab Serif</h4>
        <img src={logo172} alt="" />
        <h4>Sans Serif</h4>
        <img src={logo173} alt="" />
        <h4>Script</h4>
        <img src={logo174} alt="" />
        <h4>Blackletter</h4>
        <img src={logo175} alt="" />
      </div>
    </>,
    errorMessage: 'Please answer the question',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan16',
      percentageState: 80,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      type: 'info',
      newState: 'pertanyaan18',
      percentageState: 90,

    },
    input: {
      type: 'radio',
      placeholder: ' ',
      width: 12
    }
  },
  pertanyaan18: {
    category: 'Design',
    subCategory: 'The following questions will help you narrow down your visual preferences.',
    codeData: '18 - The following questions will help you narrow down your visual preferences',
    pertanyaan: ['That is the end of our questionnaire. We truly thank you for taking the time to complete this exercise.  If you have additional thoughts or ideas for our team, please share below.  And feel free to attach files for our reference below. '],
    errorMessage: '',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan17',
      percentageState: 85,
      type: 'info'
    },
    btn2: {
      text: 'SUBMIT',
      newState: 'done',
      percentageState: 95,
      type: 'success'
    },
    input: {
      type: 'file',
      placeholder: ' ',
      width: 12
    }
  }
}
