import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyC_ZDQiWdTlcVV1iouTcn_Pv5fi28C9Y5o",
  authDomain: "app-geonet.firebaseapp.com",
  databaseURL: "https://app-geonet.firebaseio.com",
  projectId: "app-geonet",
  storageBucket: "app-geonet.appspot.com",
  messagingSenderId: "593482794105",
  appId: "1:593482794105:web:9017a33c8793e44d3b00a8",
  measurementId: "G-XNR13Y5YV9"
};

const runBootstrap = async () => {
  const firebaseApp = firebase.initializeApp(firebaseConfig)
  let called = false;
  const unSubscribe = firebaseApp.auth().onAuthStateChanged((user: any) => {
    if (called) {
      return;
    }
    if (user) {
      console.log(user)
      called = true;
      console.log("USER", user)
      ReactDOM.render(<App uid={user.uid} firebaseApp={firebaseApp} />, document.getElementById('root'));
      unSubscribe();
    } else {
      // console.log("masuk tidak ada user")
      console.error('user null. berarti login di sini');
      firebaseApp.auth().signInAnonymously()
        .then(d => {
          console.log('User data: ', d);
        }).catch(exc => {
          console.error('Gagal login, error: ', exc);
        });
    }
  });
}
runBootstrap()

serviceWorker.unregister();
