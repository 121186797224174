import React from "react"  
import "./layout.css" 
import '../themes/css/bootstrap.min.css'
import '../themes/css/now-ui-kit.min.css'
type LayoutProps = {
  header: any
  children: any
}

const Layout = ( props: LayoutProps ) => {
   
  return (
    <>
      {props.header}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{props.children}</main>
       
      </div>
      <footer style={{background: '#2ca8ff85', textAlign: "center", color: '#000', fontSize: 12, padding: '10px 0', position: 'fixed', bottom: 0, width: '100%'}}>
          © {new Date().getFullYear()} Geonet.me The Next Level Marketing, Powerd by <a href="https://geonet.me" target="_blank" rel="noopener noreferrer">Geonet.me</a>
          {` `}
           
        </footer>
    </>
  )
}
 
export default Layout

/*
<Row className="justify-content-center"> 
         <Col xs={4} md="3">
          <img src={prop1} alt=""/>
         </Col>
         <Col xs={4} md="3">
          <img src={prop2} alt=""/>
         </Col>
         <Col xs={4} md="3">
          <img src={prop3} alt=""/>
         </Col>
         <Col xs={4} md="3">
          <img src={prop4} alt=""/>
         </Col> 

       </Row>

*/